<template>
  <v-dialog :value="dialog" max-width="610" @input="$emit('dialog')" @click:outside="close">
    <v-card class="quick-monitoring-modal">
      <h3 class="quick-monitoring-modal__title">Add Health Data</h3>
      <v-btn class="quick-monitoring-modal__close-btn border" color="primarylight3" icon rounded x-small @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row>
        <v-col cols="4">
          <v-subheader>Type</v-subheader>
        </v-col>
        <v-col cols="8">
          <Select :hideDetails="true" :items="selectOptions" label="Choose Health Data Type" @change="changeKind" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Select from "@/components/uikit/Select";
import { useAuthStore } from "@/pinia-store/auth";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { observationTypes } from "@/utils/observationTypes";

export default {
  name: "QuickMonitoringModal",
  props: ["dialog"],
  components: {
    Select,
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMonitoringStore, ["orders"]),
    selectOptions() {
      return this.orders.map((order) => {
        const observation = observationTypes.find((ob) => ob.kind === order.kind);
        return { text: observation.display, value: observation.kind };
      });
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["setPatientId", "openMonitoringFormByKind"]),
    changeKind(value) {
      this.openMonitoringFormByKind(value);
    },
    close() {
      this.$emit("dialog");
      this.$emit("close");
    },
  },

  mounted() {
    this.setPatientId(this.uid);
  },
};
</script>

<style lang="scss" scoped>
.quick-monitoring-modal {
  position: relative;
  padding: 30px;

  &__title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
